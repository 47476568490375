<template>
  <div>
    <div class="border-bottom">
      <b-button class="mb-2" variant="success" v-b-toggle.add-member>+ ADD MEMBER</b-button>
    </div>
    <b-sidebar
        ref="memberSidebar"
        id="add-member"
        right
        backdrop
        shadow
        width="450px"
        sidebar-class="sidebar-lg"
        bg-variant="white"
    >
      <template #header="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header w-100">
          <h5 class="m-0">Add Team Member</h5>
          <div>
            <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
            />
          </div>
        </div>
      </template>
      <modal-add-member></modal-add-member>
    </b-sidebar>

    <h3 class="mt-1" v-if="members==0">There are no members in this team. Click on "ADD MEMBER" to add some.</h3>

    <b-row class="mt-2">
      <b-col cols="12" xl="3" lg="4" sm="6" v-for="(oneMember, index) in members" :key="index" class="mb-2">
        <b-card no-body class="p-1 h-100 m-0">
          <b-media vertical-align="top" class="qf-team-box">
            <template #aside>
              <b-avatar size="64" :src="avatar(oneMember)"
              ></b-avatar>
            </template>
            <div class="d-flex justify-content-between">
              <h3 class="teamMemberName media-heading">
                <div>{{ oneMember.attributes.firstName }} {{ oneMember.attributes.lastName }}</div>
              </h3>
              <div>
                <b-dropdown
                        right
                        size="sm"
                        variant="flat-primary"
                        class="dropdown-icon-wrapper qf-options">
                  <template #button-content>
                    <feather-icon class="text-success" data-cy="moreOptions" icon="MoreVerticalIcon" size="18"/>
                  </template>
                  <b-dropdown-item v-b-modal.delete-modal @click="openModal(oneMember)" data-cy="removeMember" variant="outline-outline-{success}">
                    Remove Member
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <b-card-text class="teamMemberJob">
              {{ oneMember.attributes.userType }}
            </b-card-text>
          </b-media>
            <b-card-text class="teamMemberUsername mt-1">
                {{ oneMember.attributes.username }}
            </b-card-text>
        </b-card>
      </b-col>
      <b-modal
              id="delete-modal"
              ok-variant="danger"
              ok-title="Delete"
              cancel-title="Close"
              cancel-variant="outline-secondary"
              modal-class="modal-danger"
              size="sm"
              button-size="sm"
              centered
              title="Delete this Member"
              @ok="removeMember(memberToDelete)"
      >
        <b-card-text>
          Are you sure you want to remove {{memberToDelete.attributes.firstName + ' ' + memberToDelete.attributes.lastName}} from the team?
        </b-card-text>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BModal,
  BCardText,
  // BCardBody,
  BRow,
  BCol,
  BButton,
  BMedia,
  BAvatar,
  BSidebar,
  BDropdown,
  BDropdownItem,
  VBToggle,
  VBModal,
} from 'bootstrap-vue'
import ModalAddMember from "@/modals/ModalAddMember";
import Parse from 'parse';
import store from "@/store";
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BModal,
    BCardText,
    // BCardBody,
    BRow,
    BCol,
    BButton,
    BMedia,
    BAvatar,
    BSidebar,
    BDropdown,
    BDropdownItem,
    ModalAddMember
  },

  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
    Ripple,
  },

  data() {
    return {
      //members: [],
      // members(){
      //   return store.getters['app/allMembers']
      // }
      memberToDelete: {
        attributes: {}
      }
    }
  },

  mounted() {
    let prodId = this.$route.params.prodId;
    this.getTeamMembers(prodId);
  },

  computed: {
    curProduction() {
      return store.getters['app/currentProduction'];
    },
    members(){
      return store.getters['app/allMembers']
    }
  },

  watch: {
    curProduction: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal && newVal.id) {
          this.activeProduction = newVal;
          this.getTeamMembers(newVal.id);
          //console.log("New active production ID: ", newVal);
        }
      }
    },
  },

  methods: {
    openModal(oneMember){
      console.log('curr member id: ', oneMember)
      this.memberToDelete = oneMember
    },
    avatar(user) {
      if (user.attributes.avatar) {
        return user.attributes.avatar._url;
      } else {
        return null;
      }
    },
    getTeamMembers(prodId) {
      //let self = this;

      const Production = Parse.Object.extend("Production");
      const query = new Parse.Query(Production);

      query.get(prodId)
          .then(function (results) {
            const teamMembers = results.get("teamMembers");
            const userQuery = new Parse.Query(Parse.User);
            userQuery.containedIn("objectId", teamMembers);
            userQuery.find().then(function (users) {
              console.log("Users: ", users);
              store.commit('app/SET_MEMBERS', users);
              //self.members = users;
            })
          })
    },
    removeMember(teamMember) {
      let self = this;
      let curMemberIndex = self.members.indexOf(teamMember);
      console.log("selected member index in arr: ", curMemberIndex)
      this.curProduction.remove('teamMembers', teamMember.id);
      this.curProduction.save().then(function(saveResult){

        store.commit('app/REMOVE_MEMBER', curMemberIndex);
        store.commit('app/SET_PRODUCTION', saveResult);

      }).catch(function(err){
        console.log("Err on save: ", err);
      });
    },
  }
}

</script>

<style scoped>
.qf-team-box.media-body {
    width: calc(100% - 65px);
    padding-right: 5px;
}

.qf-team-box {

}

.teamMemberName {
  font-size: 16px;
}

.teamMemberJob {
  font-size: 14px;
  margin: 0;
}

.teamMemberUsername {
  font-size: 12px;
}

</style>
