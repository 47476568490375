<template>
    <div class="h-100 p-relative">
        <div class="qf-search-filters">
            <div class="card p-2 m-0">
                <validation-observer>
                    <b-form-group class="pb-1">
                        <validation-provider
                                v-slot="{ errors }"
                                name="Email"
                                rules="required"
                        >
                            <label for="memberEmail" class="pt-1">Email: <sup class="text-danger">*</sup></label>
                            <b-form-input name="name"
                                          id="memberEmail"
                                          v-model="userEmail"
                                          placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small class="text-danger" v-if="showExisting">This member is already on the team!</small>
                        </validation-provider>
                    </b-form-group>
                </validation-observer>
                
                <div class="d-flex justify-content-between align-items-center mb-2" v-if="showResult">
                    <div class="d-flex align-items-center">
                        <b-avatar size="50" class="mr-1" :src="avatar(foundUser)"/>
                        <h3 class="producer-name">
                            <div>{{ foundUser.attributes.firstName }}</div>
                            <div>{{ foundUser.attributes.lastName }}</div>
                        </h3>
                    </div>
                    <div>
                        <b-button variant="primary" @click="addMember(foundUser)" size="sm">
                            <feather-icon icon="PlusIcon"/>
                            ADD
                        </b-button>
                    </div>
                </div>
                
                <b-button
                        variant="outline-primary"
                        class="mb-1"
                        @click="cancelModal()"
                >CANCEL
                </b-button>
            
            </div>
        </div>
    </div>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate';
    import {required, email} from '@validations';
    import {
        // //BForm,
        // BFormFile,
        BFormGroup,
        BFormInput,
        BButton,
        BAvatar,
        // BFormTextarea
    } from 'bootstrap-vue'
    import Parse from "parse";
    import store from "@/store";
    //import ToastificationContent from "@core/components/toastification/ToastificationContent";

    export default {
        name: "ModalAddMember",
        components: {
            // BForm,
            // BFormFile,
            BFormGroup,
            BFormInput,
            BButton,
            BAvatar,
            // BFormTextarea,
            ValidationProvider,
            ValidationObserver,
        },
        data() {
            return {
                required,
                email,
                userEmail: '',
                awaitingSearch: false,
                showResult: false,
                showExisting: false,
                foundUser: {},
                memberId: null,
            }
        },

        mounted() {
            let currentUser = Parse.User.current();
            if (currentUser) {
                this.currentUser = currentUser;
                this.curUserId = currentUser.id;
            }
        },
        computed: {
            curProduction() {
                return store.getters['app/currentProduction'];
            },
        },
        methods: {
            cancelModal() {
                this.$root.$emit('bv::toggle::collapse', 'add-member');
            },
            getMember() {
                let self = this;
                let parseUsers = Parse.User;
                let memberQuery = new Parse.Query(parseUsers);

                memberQuery.contains('username', this.userEmail);
                memberQuery.equalTo('userType', 'prospect');
                memberQuery.equalTo('userType', 'producer');
                memberQuery.find().then(function (results) {
                    if(results.length > 0){
                      self.foundUser = results[0];
                      self.showResult = true;
                    }
                    self.awaitingSearch = false;
                }).catch(function (err) {
                  console.log("Search error: ", err);
                  self.awaitingSearch = false;
                  self.showResult = false;
                })
            },
            avatar(user) {
                if (user && user.attributes.avatar) {
                    return user.attributes.avatar._url;
                } else {
                    return null;
                }
            },
            addMember(memberObj){
                let self = this;
                this.curProduction.add('teamMembers', memberObj.id);
                this.curProduction.save().then(function (saveResult) {
                    self.userEmail = '';

                    store.commit('app/SET_PRODUCTION', saveResult);
                    store.commit('app/ADD_NEW_MEMBER', memberObj);
                    self.cancelModal();
                }).catch(function (err) {
                    console.log("Err on save: ", err);
                });
            },
        },
        watch: {
            userEmail: function (emailString) {
                this.showExisting = false;
                this.showResult = false;

                if (!this.awaitingSearch) {
                    setTimeout(() => {
                      if (emailString !== '') {
                        let curTeam = store.getters['app/allMembers'];
                        let userExists = [];
                        if (curTeam) {
                          userExists = curTeam.filter(obj => {
                            return obj.attributes.username === emailString
                          });
                        }

                        if (userExists.length > 0) {
                          this.showExisting = true;
                          this.awaitingSearch = false;
                          this.showResult = false;
                        } else {
                          this.awaitingSearch = true;
                          this.showExisting = false;
                          this.getMember();
                        }
                    }
                    }, 250);
                }
            },
        },
    }
</script>

<style scoped>
    .producer-name {
        font-size: 16px;
    }
</style>
